// Basic
import { Injectable } from '@angular/core';

// Api Services
import { ApplicationDataDatabaseService } from '@providers/db/application-data-database.service';

// Services
import { LoggerService } from '@providers/services/logger.service';

// Models
import { TimerSettingModel } from '@core/model/timer-setting.model';

// Constants
import { APPLICATION_CONSTANTS } from '@core/constants/application-data.constants';

// Moment
import moment from 'moment';

/**
 * Store to handle general application data.
 */
@Injectable({ providedIn: 'root' })
export class ApplicationDataStore {
	/**
	 * stores the number of attempts and the next date to be asked
	 */
	private currentPrivacyTimer: TimerSettingModel;

	/**
	 * Constructor to declare all the necesary to initialize the class.
	 * @param appDataDB application data database access.
	 * @param loggerService logger service
	 */
	constructor(
		private appDataDB: ApplicationDataDatabaseService,
		private loggerService: LoggerService
	) {
		this.initPrivacyPermission();
	}

	/**
	 * Initialize currentPrivacyTimer from the DB | first time.
	 */
	private initPrivacyPermission(): void {
		this.appDataDB.get(APPLICATION_CONSTANTS.PRIVACY_POPUP_STATUS).then(
			(answer: TimerSettingModel) => (this.currentPrivacyTimer = answer),
			() => {
				const timerFirstTimeSettings: TimerSettingModel =
					this.getStartingTimer();
				this.appDataDB
					.set(
						APPLICATION_CONSTANTS.PRIVACY_POPUP_STATUS,
						timerFirstTimeSettings
					)
					.then(
						() => (this.currentPrivacyTimer = timerFirstTimeSettings),
						() =>
							this.loggerService.error(
								this,
								`AppDataDB SET FAIL for: ${APPLICATION_CONSTANTS.PRIVACY_POPUP_STATUS} ${timerFirstTimeSettings}.`
							)
					);
			}
		);
	}

	/**
	 * Use to get a default starting timer.
	 */
	private getStartingTimer(): TimerSettingModel {
		const timerFirstTimeSettings: TimerSettingModel = {
			timerDate: undefined,
			attempts: 0
		} as TimerSettingModel;
		return timerFirstTimeSettings;
	}

	/**
	 * Search if the app should ask for permissions again
	 */
	public shouldAskPrivacyPermission(): boolean {
		let shouldAsk = true;
		if (this.currentPrivacyTimer.attempts === 3) {
			// 3 attempts, should never ask again
			shouldAsk = false;
		} else {
			if (!this.currentPrivacyTimer.timerDate) {
				// First time since reset
				shouldAsk = true;
			} else {
				// Compare current date to stored date
				const currentDate: number = moment().valueOf();
				const timerDate: number = this.currentPrivacyTimer.timerDate;
				shouldAsk = currentDate >= timerDate;
			}
		}
		return shouldAsk;
	}

	/**
	 * Delays the timer inside currentPrivacyTimer to fit the next attempt
	 */
	public delayPrivacyPermission(): void {
		this.currentPrivacyTimer.attempts++;
		// this.currentPrivacyTimer.timerDate = moment().valueOf() + 2592000000 * this.currentPrivacyTimer.attempts;
		this.currentPrivacyTimer.timerDate =
			moment().valueOf() + 60000 * this.currentPrivacyTimer.attempts;
		this.setPrivacyTimer();
	}

	/**
	 *  Resets privacy permissions
	 */
	public resetPrivacyPermission(): void {
		this.currentPrivacyTimer = this.getStartingTimer();
		this.setPrivacyTimer();
	}

	/**
	 * Set the privacyTimer in the DB
	 */
	public setPrivacyTimer(): void {
		this.appDataDB
			.set(APPLICATION_CONSTANTS.PRIVACY_POPUP_STATUS, this.currentPrivacyTimer)
			.then(
				() =>
					this.loggerService.info(
						this,
						`AppDataDB SET SUCCESS for: ${APPLICATION_CONSTANTS.PRIVACY_POPUP_STATUS} ${this.currentPrivacyTimer}.`
					),
				() =>
					this.loggerService.error(
						this,
						`AppDataDB SET FAIL for: ${APPLICATION_CONSTANTS.PRIVACY_POPUP_STATUS} ${this.currentPrivacyTimer}.`
					)
			);
	}

	/**
	 * Gets the unique device Id from the DB if exists.
	 */
	public getUniqueDeviceId(): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.appDataDB.get(APPLICATION_CONSTANTS.BROWSER_X_GUID).then(
				answer => resolve(answer),
				error => reject(error)
			);
		});
	}

	/**
	 * Sets or updates the unique device Id in the DB.
	 * @param id Value of the unique device id
	 */
	public setUniqueDeviceId(id: string): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this.appDataDB.set(APPLICATION_CONSTANTS.BROWSER_X_GUID, id).then(
				answer => resolve(answer),
				error => reject(error)
			);
		});
	}

	/**
	 * Save the first time that the application is initiated
	 */
	public setfirstTimeInitApplication() {
		return new Promise<any>((resolve, reject) => {
			this.appDataDB
				.set(APPLICATION_CONSTANTS.FIRST_TIME_INIT_APPLICATION, 'init')
				.then(
					answer => resolve(answer),
					error => reject(error)
				);
		});
	}
	/**
	 * Check if the application have been initiated before
	 */
	public isfirstTimeInitApplication() {
		return new Promise<any>((resolve, reject) => {
			this.appDataDB
				.get(APPLICATION_CONSTANTS.FIRST_TIME_INIT_APPLICATION)
				.then(
					_answer => resolve(true),
					_error => reject(false)
				);
		});
	}
}
