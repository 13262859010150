import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import {
	MachineActionTypes,
	saveMachineVersionHistory,
	updateCachedMachineVersion
} from '../machine.actions';
import { lastValueFrom } from 'rxjs';
import { MachineService } from '../../infrastructure/service/machine.service';
import { Machine, MachineHistoryVersion } from '../../core/model/machine';
import { Store } from '@ngrx/store';
import { AppState } from 'app/state';
import { ExtractMachineInfoService } from '@modules/machine/core/service/extract-machine-info.service';
import { DocumentApiService } from '@modules/document/infrastructure/service/document.service';
import { TranslationStore } from '@providers/stores/translation-store.service';

@Injectable()
export class VersionHistoryEffects {
	getMachineVersionHistory$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MachineActionTypes.requestMachineVersionHistory),
			mergeMap((action: any) =>
				this.machineService
					.getMachineVersionHistory(
						action.serialnumber,
						action.manufacturerproductnumber
					)
					.pipe(
						map((versionHistory: any[]) => {
							// We can't dispatch cacheMachineVersion effect, because we only have one item for each machine in version history database
							// and we found asynchronous problems when accesing to this item
							Promise.all(
								versionHistory.map(version => {
									return lastValueFrom(this.machineService
										.getMachineVersion(
											action.serialnumber,
											action.manufacturerproductnumber,
											version
										)
									);
								})
							).then(async machineVersions => {
								for (let index = 0; index < machineVersions.length; index++) {
									const machineVersion = machineVersions[index];
									const cacheMachineVersionAction =
										await this.cacheMachineVersion(
											machineVersion,
											action,
											index === 0
										);
									this.documentApiService.cacheDocumentsFromMachine(
										machineVersion
									);
									this.translationStore.cacheTranslationsFromMachine(
										machineVersion
									);

									this.store.dispatch(cacheMachineVersionAction);
								}
							});

							return this.saveMachineVersionHistory(
								action.serialnumber,
								versionHistory
							);
						})
					)
			)
		);
	});

	getMachineVersionHistoryFromDB$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MachineActionTypes.requestMachineVersionHistoryFromDB),
			mergeMap((action: any) =>
				this.machineService
					.getMachineVersionHistoryFromDB(action.serialnumber)
					.pipe(
						map((versionhistory: MachineHistoryVersion[]) => {
							return saveMachineVersionHistory({ versionhistory });
						})
					)
			)
		);
	});

	private async cacheMachineVersion(
		version: any,
		action: any,
		isLatest?: boolean
	) {
		const machine: Machine = this.extractMachineInfoService.extractMachineInfo(
			version,
			action
		);

		await this.machineService.saveMachineIntoDB(machine, isLatest);
		await this.machineService.updateCachedMachineVersionIntoDB(machine, true);

		return updateCachedMachineVersion({
			version: version.plantversion,
			cached: true
		});
	}

	private saveMachineVersionHistory(
		serialNumber: string,
		versionHistory: any[]
	) {
		const machineHistoryVersion: MachineHistoryVersion[] = versionHistory.map(
			version => ({
				version,
				cached: false
			})
		);

		this.machineService.saveMachineVersionHistoryIntoDB(
			serialNumber,
			machineHistoryVersion
		);

		return saveMachineVersionHistory({ versionhistory: machineHistoryVersion });
	}

	constructor(
		private actions$: Actions,
		private machineService: MachineService,
		private store: Store<AppState>,
		private extractMachineInfoService: ExtractMachineInfoService,
		private documentApiService: DocumentApiService,
		private translationStore: TranslationStore
	) {}
}
