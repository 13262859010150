import { Component } from '@angular/core';
import { LoaderService } from './loader.service';

@Component({
    selector: 'sdt-loader',
    template: `<div *ngIf="loading$ | async" class="progressindicator">
		<ion-spinner></ion-spinner>
	</div>`,
    standalone: false
})
export class LoaderComponent {
	loading$ = this.loaderService.isLoading;
	constructor(private readonly loaderService: LoaderService) {}
}
