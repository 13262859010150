import { createAction, props } from '@ngrx/store';
import {
	Machine,
	MachineHistoryVersion,
	OfflineMachine
} from '../core/model/machine';
//import { Geolocation } from '@capacitor/geolocation';
import { RecacheRunningModeModel } from '@modules/app/core/model/app.model';

export enum MachineActionTypes {
	requestMachineVersion = '[API-MACHINE] REQUEST MACHINE VERSION',
	requestMachineVersionFromDB = '[DB-MACHINE] REQUEST MACHINE VERSION',
	requestMachineLatestVersion = '[API-MACHINE] REQUEST MACHINE LATEST VERSION',
	requestMachineLatestVersionFromDB = '[DB-MACHINE] REQUEST MACHINE LATEST VERSION',
	cacheMachineVersion = '[DB-MACHINE] CACHE MACHINE VERSION',
	cacheMachineVersions = '[API-MACHINE] CACHE MACHINE VERSIONS',
	recacheAllMachines = '[API-MACHINE] RECACHE ALL MACHINES',
	updateCachedMachineVersion = '[MACHINE] UPDATE CACHED MACHINE VERSION',
	saveMachineVersion = '[MACHINE] SAVE MACHINE VERSION',
	requestMachineError = '[API-MACHINE] REQUEST MACHINE ERROR',
	requestMachineVersionHistory = '[API-MACHINE] REQUEST MACHINE VERSION HISTORY',
	requestMachineVersionHistoryFromDB = '[DB-MACHINE] REQUEST MACHINE VERSION HISTORY',
	saveMachineVersionHistory = '[MACHINE] SAVE MACHINE VERSION HISTORY',
	requestMachineLocation = '[API-MACHINE] REQUEST MACHINE LOCATION',
	deleteMachineLocationFromDB = '[DB-MACHINE] DELETE MACHINE LOCATION',
	saveMachineLocation = '[MACHINE] SAVE MACHINE LOCATION',
	saveOfflineMachineIntoDB = '[DB-MACHINE] SAVE OFFLINE MACHINE',
	deleteOfflineMachineFromDB = '[DB-MACHINE] DELETE OFFLINE MACHINE',
	checkOfflineMachines = '[DB-MACHINE] CHECK OFFLINE MACHINES',
	cacheOfflineMachine = '[API-MACHINE] CACHE OFFLINE MACHINE',
	navigateToMachineDetails = '[MACHINE] NAVIGATE TO MACHINE DETAILS',
	noOpAction = '[DB-MACHINE] NO OP ACTION'
}

export const requestMachineLatestVersion = createAction(
	MachineActionTypes.requestMachineLatestVersion,
	props<{
		serialnumber: string;
		manufacturerproductnumber: string;
	}>()
);

export const requestMachineLatestVersionFromDB = createAction(
	MachineActionTypes.requestMachineLatestVersionFromDB,
	props<{
		serialnumber: string;
		manufacturerproductnumber: string;
	}>()
);

export const requestMachineVersion = createAction(
	MachineActionTypes.requestMachineVersion,
	props<{
		serialnumber: string;
		manufacturerproductnumber: string;
		version: string;
	}>()
);

export const requestMachineVersionFromDB = createAction(
	MachineActionTypes.requestMachineVersionFromDB,
	props<{
		serialnumber: string;
		manufacturerproductnumber: string;
		version: string;
	}>()
);

export const cacheMachineVersion = createAction(
	MachineActionTypes.cacheMachineVersion,
	props<{
		serialnumber: string;
		manufacturerproductnumber: string;
		version: string;
		isLatest?: boolean;
	}>()
);

export const cacheMachineVersions = createAction(
	MachineActionTypes.cacheMachineVersions,
	props<{
		serialnumber: string;
		manufacturerproductnumber: string;
		machineVersionsToRecache: any[];
		recacheRunningMode: RecacheRunningModeModel.RecacheRunningModeType;
	}>()
);

export const recacheAllMachines = createAction(
	MachineActionTypes.recacheAllMachines,
	props<{
		recacheRunningMode: RecacheRunningModeModel.RecacheRunningModeType;
		currentUserRoles: string[];
	}>()
);

export const updateCachedMachineVersion = createAction(
	MachineActionTypes.updateCachedMachineVersion,
	props<{
		version: string;
		cached: boolean;
	}>()
);

export const saveMachineVersion = createAction(
	MachineActionTypes.saveMachineVersion,
	props<{ machine: Machine }>()
);

export const requestMachineError = createAction(
	MachineActionTypes.requestMachineError,
	props<{
		serialnumber: string;
		manufacturerproductnumber: string;
		error: any;
	}>()
);

export const requestMachineVersionHistory = createAction(
	MachineActionTypes.requestMachineVersionHistory,
	props<{
		serialnumber: string;
		manufacturerproductnumber: string;
	}>()
);

export const requestMachineVersionHistoryFromDB = createAction(
	MachineActionTypes.requestMachineVersionHistoryFromDB,
	props<{
		serialnumber: string;
		manufacturerproductnumber: string;
	}>()
);

export const saveMachineVersionHistory = createAction(
	MachineActionTypes.saveMachineVersionHistory,
	props<{
		versionhistory: MachineHistoryVersion[];
	}>()
);

export const requestMachineLocation = createAction(
	MachineActionTypes.requestMachineLocation,
	props<{
		serialnumber: string;
	}>()
);

export const deleteMachineLocationFromDB = createAction(
	MachineActionTypes.deleteMachineLocationFromDB,
	props<{
		serialnumber: string;
	}>()
);

export const saveMachineLocation = createAction(
	MachineActionTypes.saveMachineLocation,
	props<{
		location: Geolocation;
	}>()
);

export const checkOfflineMachines = createAction(
	MachineActionTypes.checkOfflineMachines,
	props<Record<string, unknown>>()
);

export const saveOfflineMachineIntoDB = createAction(
	MachineActionTypes.saveOfflineMachineIntoDB,
	props<{
		serialnumber: string;
		machine: OfflineMachine;
	}>()
);

export const deleteOfflineMachineFromDB = createAction(
	MachineActionTypes.deleteOfflineMachineFromDB,
	props<{
		serialnumber: string;
	}>()
);

export const cacheOfflineMachine = createAction(
	MachineActionTypes.cacheOfflineMachine,
	props<{
		serialnumber: string;
		manufacturerproductnumber: string;
	}>()
);

export const navigateToMachineDetails = createAction(
	MachineActionTypes.navigateToMachineDetails,
	props<{
		serialnumber: string;
		manufacturerproductnumber: string;
	}>()
);

export const noOpAction = createAction(
	MachineActionTypes.noOpAction,
	props<Record<string, unknown>>()
);
