import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import {
	MachineActionTypes,
	cacheMachineVersions,
	noOpAction
} from '../machine.actions';
import { MachineService } from '../../infrastructure/service/machine.service';
import { forkJoin, of , firstValueFrom} from 'rxjs';
import { Machine } from '../../core/model/machine';
import { ExtractMachineInfoService } from '@modules/machine/core/service/extract-machine-info.service';
import { DocumentApiService } from '@modules/document/infrastructure/service/document.service';
import { AppState } from 'app/state';
import { Store } from '@ngrx/store';
import { RecacheRunningModeModel } from '@modules/app/core/model/app.model';
import { getRecacheRunningMode } from '@modules/app/selectors';
import { TranslationStore } from '@providers/stores/translation-store.service';

@Injectable()
export class CacheMachineEffects {
	currentRecacheRunningMode: RecacheRunningModeModel.RecacheRunningModeType;
	// Indicator, if document DB already has been cleared
	documentsCleared = false;

	cacheMachineVersions$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MachineActionTypes.cacheMachineVersions),
			map((action: any) => {
				if (this.currentRecacheRunningMode === action.recacheRunningMode) {
					Promise.all(
						action.machineVersionsToRecache
							.filter(version => !version.cached)
							.map(async version => {
								return await this.machineService
									.getMachineVersion(
										action.serialnumber,
										action.manufacturerproductnumber,
										version.version
									)
									.toPromise();
							})
					).then(async machineVersions => {
						for (let index = 0; index < machineVersions.length; index++) {
							// Remove documents from local DB to recache them with new permissions
							// Clear document DB only once for one recaching sequence
							if (!this.documentsCleared) {
								this.removeDocumentsFromDB();
							}
							const machineVersion = machineVersions[index];
							await this.cacheMachineVersion(
								machineVersion,
								action,
								index === 0
							);
							// Recache documents according to new permissions
							this.documentApiService.cacheDocumentsFromMachine(machineVersion);
							this.translationStore.cacheTranslationsFromMachine(
								machineVersion
							);
						}
					});
				}

				return noOpAction({});
			})
		);
	});

	recacheAllMachines$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(MachineActionTypes.recacheAllMachines),
			mergeMap((action: any) =>
				forkJoin([
					this.machineService.getAllMachinesVersionHistoryFromDB(),
					this.machineService.getAllMachinesFromDB(),
					of(action)
				])
			),
			mergeMap(([cachelistVersionsHistory, cachelistMachines, action]) => {
				const userRoles = action.currentUserRoles;
				const recacheRunningMode = action.recacheRunningMode;

				// Clear documents to recache
				this.documentsCleared = false;

				return cachelistVersionsHistory.map(async machineVersions => {
					const serialnumber = machineVersions.key;
					const listVersion = [...machineVersions.values];

					const machine = cachelistMachines.find(cachedMachine =>
						cachedMachine.key.includes(serialnumber)
					);
					if (machine) {
						const manufacturerproductnumber =
							machine.values.manufacturerproductnumber;

						const machineVersionsToRecache =
							await this.getMachineVersionsToRecache(
								serialnumber,
								listVersion,
								userRoles
							);

						return cacheMachineVersions({
							serialnumber,
							manufacturerproductnumber,
							machineVersionsToRecache,
							recacheRunningMode
						});
					}
				});
			}),
			mergeMap((action: any[]) => action)
		);
	});

	private async getMachineVersionsToRecache(
		serialNumber: string,
		machineVersions: any[],
		currentRoles: string[]
	) {
		const cachedMachineVersions = await Promise.all(
			machineVersions.map(async (machineVersion, index) => {
				try {
					return {
						value: await firstValueFrom(
							this.machineService.getMachineVersionFromDB(
								serialNumber,
								index === 0 ? 'latest' : machineVersion.version
							)
						)
					};
				} catch (error) {
					return {
						value: {},
						machineVersion
					};
				}
			})
		);

		return cachedMachineVersions.map(filteredCachedMachineVersion => {
			return {
				...filteredCachedMachineVersion.machineVersion,
				cached:
					filteredCachedMachineVersion.value.role &&
					filteredCachedMachineVersion.value.role.length === currentRoles.length
						? filteredCachedMachineVersion.value.role.every(value =>
								currentRoles.includes(value)
						  )
						: false
			};
		});
	}

	private async cacheMachineVersion(
		version: any,
		action: any,
		isLatest?: boolean
	) {
		const machine: Machine = this.extractMachineInfoService.extractMachineInfo(
			version,
			action
		);

		await this.machineService.saveMachineIntoDB(machine, isLatest);
		await this.machineService.updateCachedMachineVersionIntoDB(machine, true);
	}

	private async removeDocumentsFromDB() {
		this.documentApiService.removeAll();
		this.documentsCleared = true;
	}

	constructor(
		private actions$: Actions,
		private machineService: MachineService,
		private extractMachineInfoService: ExtractMachineInfoService,
		private store: Store<AppState>,
		private documentApiService: DocumentApiService,
		private translationStore: TranslationStore
	) {
		this.store
			.select(getRecacheRunningMode)
			.subscribe(
				recacheRunningMode =>
					(this.currentRecacheRunningMode = recacheRunningMode)
			);
	}
}
