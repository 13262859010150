import { mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs/internal/Observable';
import { UserModel } from '@core/model/user.model';
import { ConfigurationService } from '@core/configuration.service';
const { authorizationParams } = ConfigurationService.environment.mobileAuthConfig;

@Injectable({
	providedIn: 'root'
})
export class AuthNativeService {
	constructor(private readonly auth: AuthService) { }

	public get currentUser$(): Observable<UserModel> {
		return new Observable(subscriber => {
			this.auth.idTokenClaims$.subscribe({
				next: ({ name, email, picture: avatar, exp: expiresIn, __raw: token }) => {
					subscriber.next({ name, email, avatar, expiresIn, token });
					subscriber.complete();
				},
				error: error => subscriber.error(error)
			});
		});
	}

	login() {
		return this.auth.loginWithRedirect({ display: 'sup' } as any)
			.pipe(mergeMap(() => Browser.open({ url: authorizationParams.redirect_uri, windowName: '_self' })));
	}

	logout() {
		this.auth.logout({ returnTo: authorizationParams.redirect_uri } as any)
			.pipe(
				mergeMap(async () => {
					await Browser.open({ url: authorizationParams.redirect_uri, windowName: '_self' });
					window.location.reload();
				})
			)
			.subscribe();
	}
}
