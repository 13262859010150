import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ConfigurationService } from '@core/configuration.service';
import { throwError, TimeoutError } from 'rxjs';
import {
	ApiError,
	ApiErrorBadInput,
	ApiErrorNotFound,
	ApiErrorTimeOut,
	ApiErrorExceededRequests,
	ApiErrorForbidden,
	ApiErrorUnauthorized
} from '@core/error/api-error/api-error.module';
import { catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	urlApi: string;
	urlBase: string;
	
	constructor(public http: HttpClient) {
		this.urlBase = ConfigurationService.environment.$apiConfig.baseEndpoint;
		this.urlApi = ConfigurationService.environment.$apiConfig.apiBaseEndpoint;
	}

	get(uri: string, headers?: HttpHeaders) {
		return this.http
			.get(`${this.urlBase}${this.urlApi}${uri}`, { headers })
			.pipe(catchError(this.handleError));
	}

	private handleError(error: HttpErrorResponse) {
		if (error instanceof TimeoutError) {
			return throwError(() => new ApiErrorTimeOut());
		} else if (error.status === 400) {
			return throwError(() => new ApiErrorBadInput(error));
		} else if (error.status === 401) {
			return throwError(() => new ApiErrorUnauthorized());
		} else if (error.status === 403) {
			return throwError(() => new ApiErrorForbidden());
		} else if (error.status === 404) {
			return throwError(() => new ApiErrorNotFound());
		} else if (error.status === 429) {
			return throwError(() => new ApiErrorExceededRequests(error.error));
		} else if (error.status === 0) {
			return throwError(() => new ApiErrorForbidden());
		} else {
			return throwError(() => new ApiError(error));
		}
	}
}
