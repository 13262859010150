import { Observable, firstValueFrom, tap } from 'rxjs';
import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Globalization } from '@awesome-cordova-plugins/globalization/ngx';
import { TranslateService as Translate } from '@ngx-translate/core';

import { ToastService } from './toast.service';
import { LoggerService } from '@providers/services/logger.service';
import { SETTINGS_CONSTANTS } from '@core/constants/settings.constants';
import { SettingsStore } from '@providers/stores/settings-store.service';

@Injectable({ providedIn: 'root' })
export class TranslateService {
	constructor(
		private platform: Platform,
		private globalization: Globalization,
		private settingsStore: SettingsStore,
		private translate: Translate,
		private toastService: ToastService,
		private loggerService: LoggerService
	) {}

	public initTranslate() {
		return new Promise<any>(resolve => {
			this.settingsStore.getLanguage().then(
				currentLanguageCountryKey => {
					this._translateServiceInitialization(currentLanguageCountryKey).then(
						() => resolve(undefined)
					);
				},
				() => {
					// Try to get default platform language
					if (this.platform.is('capacitor')) {
						this.globalization
							.getPreferredLanguage()
							.then(languageTag => {
								const language: string = languageTag.value.substring(0, 2); // Language tag is according to BCP-47, for example "en" or "en-us"
								const languageIndex: number =
									SETTINGS_CONSTANTS.LANGUAGES.AVAILABLE_LANGUAGES.indexOf(
										language
									);
								if (languageIndex >= 0) {
									this._translateServiceInitialization(
										SETTINGS_CONSTANTS.LANGUAGES.AVAILABLE_LANGUAGES[
											languageIndex
										]
									).then(() => {
										this.settingsStore.setLanguage(
											SETTINGS_CONSTANTS.LANGUAGES.AVAILABLE_LANGUAGES[
												languageIndex
											]
										); // default langauge
										resolve(undefined);
									});
								} else {
									throw Error(
										'Language of platform is not supported by translation'
									);
								}
							})
							.catch(error => {
								this._translateServiceInitialization(
									SETTINGS_CONSTANTS.LANGUAGES.AVAILABLE_LANGUAGES[0]
								).then(() => {
									this.settingsStore.setLanguage(
										SETTINGS_CONSTANTS.LANGUAGES.AVAILABLE_LANGUAGES[0]
									); // default langauge
									this.loggerService.info(
										this,
										'Unable to define a language for platform:',
										error,
										'Default language has been set'
									);
									resolve(undefined);
								});
							});
					} else {
						this._translateServiceInitialization(
							SETTINGS_CONSTANTS.LANGUAGES.AVAILABLE_LANGUAGES[0]
						).then(() => {
							this.settingsStore.setLanguage(
								SETTINGS_CONSTANTS.LANGUAGES.AVAILABLE_LANGUAGES[0]
							); // default langauge
							this.loggerService.info(
								this,
								'Unable to define a language for platform.',
								'Default language has been set'
							);
							resolve(undefined);
						});
					}
				}
			);
		});
	}

	public getTranslation(lang: string): Observable<any> {
		return this.translate.use(lang).pipe(
		  tap(() => {
			console.log(`Language switched to ${lang}`);
		  })
		);
	  }

	public currentLanguage(): string {
		return this.translate.currentLang;
	}

	public async use(selectedLanguage: string) {
		try {
			await firstValueFrom(this.translate.use(selectedLanguage));
	
			try {
				await this.settingsStore.setLanguage(selectedLanguage);
				this.toastService.showToast({
					message: this._instant('toast.settings.success1.message'),
					duration: 5000
				});
			} catch (error) {
				this.loggerService.error(this, 'Error on saving language selected', error);
			}
	
		} catch (error) {
			this.toastService.showToast({
				message: this._instant('toast.settings.error1.message'),
				duration: 5000
			});
			this.loggerService.error(this, 'Error on translate.use', error);
		}
	}

	public instant(key: string, params?) {
		return this._instant(key, params);
	}

	public defaultLanguage() {
		return SETTINGS_CONSTANTS.LANGUAGES.AVAILABLE_LANGUAGES[0];
	}

	private _instant(key: string, params?) {
		return this.translate.instant(key, params);
	}

	private async _translateServiceInitialization(_languageKey: string): Promise<any> {
		// Set the default language for translation strings, and the current language.
		this.translate.setDefaultLang(this.defaultLanguage());
	
		// Set your language here
		// As language switch is turned off, initialize with English language
		try {
			const result = await firstValueFrom(this.translate.use(this.defaultLanguage()));
			return result;
		} catch (error) {
			// Handle error as necessary
			console.error('Error initializing translation service:', error);
			throw error;
		}
	}
}
