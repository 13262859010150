import { Injectable } from '@angular/core';
import * as jwtGenerator from 'jsonwebtoken';
import type { JwtPayload } from "jsonwebtoken";

// Constants
import { AUTH0_CONSTANTS } from '@core/constants/auth0.constants';

@Injectable({
	providedIn: 'root'
})
export class JwtService {
	constructor() {}

	getBaseToken(): string {
		return AUTH0_CONSTANTS.BASE_TOKEN;
	}

	decodeToken(token: string): string | JwtPayload {
		return jwtGenerator.decode(token);
	}

	getRoles(token: string): string[] {
		const decodedToken = this.decodeToken(token);
		return decodedToken['https://sidriveiqtwin-roles'];
	}
}
