import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import {isPlatform, LoadingController, Platform} from '@ionic/angular';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { SettingsStore } from '@providers/stores/settings-store.service';
import { ApplicationService } from '@providers/services/application.service';
import { NetworkStatus } from '@providers/services/network-status.service';

import { AuthService } from '@core/services/auth/auth.service';
import { UserModel } from '@core/model/user.model';
import { PageModel } from '@core/model/page.model';
import { SafeResourceUrl } from '@angular/platform-browser';
import { RecacheRunningModeModel } from '@modules/app/core/model/app.model';
import { StatusBar, Style } from '@capacitor/status-bar';
import { getIsLoading } from '@modules/app/selectors';
import { AppState, FavoritesActions } from 'app/state';
import { saveIsOnline, saveRecacheRunningMode } from '@modules/app/actions';
import {
	checkOfflineMachines,
	recacheAllMachines
} from '@modules/machine/actions';
import { TranslateService } from '@providers/services/translate.service';
@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	public rootPage: any;
	public currentEntryList: PageModel[];
	public menuProfile: UserModel;
	public profileSafeAvatar: SafeResourceUrl;

	private loading: HTMLIonLoadingElement;
	private loadingSubscription: Subscription;
	private networkSubscription: Subscription;
	private loadingInPresent: boolean = false;

	constructor(
		private readonly store: Store<AppState>,
		private readonly authService: AuthService,
		private readonly settingsStore: SettingsStore,
		private readonly networkStatusService: NetworkStatus,
		private readonly loadingController: LoadingController,
		private readonly translationService: TranslateService,
		private readonly applicationService: ApplicationService,
		private platform: Platform
	) {
		this.platform.ready().then(() => {
			const iosOrAndroid = isPlatform('ios') || isPlatform('android');
			if(iosOrAndroid) {
				StatusBar.setOverlaysWebView({ overlay: false });
				StatusBar.setStyle({ style: Style.Light });
			}
		});
	}

	ngOnInit(): void {
		this.initializeApp();
	}

	ngOnDestroy(): void {
		this.loadingSubscription.unsubscribe();
		this.networkSubscription.unsubscribe();
	}

	public getValueForTesting(str: string) {
		return (this.constructor.name || '').concat('.', str);
	}

	private initializeApp() {
		this.applicationService.initilizeApplication().then(() => {
			this.store.dispatch(FavoritesActions.requestFavorites({}));

			this.authService.handleLoadUser().then(async () => await this.handleUserRoles());

			this.settingsStore.setBeepPreference(true);

			this.networkSubscription = this.networkStatusService
				.onlineObserver()
				.subscribe(isOnline => {
					this.store.dispatch(saveIsOnline({ isOnline }));
					if (isOnline) {
						this.store.dispatch(checkOfflineMachines({}));
					}
				});

			this.loadingSubscription = this.store
				.select(getIsLoading)
				.subscribe(isLoading => {
					if (isLoading) {
						this._presentLoading();
					} else {
						this._dismissLoading();
					}
				});
		});
	}

	private async handleUserRoles() {
		const userRoles = await this.authService.getUserRole();

		this.store.dispatch(
			saveRecacheRunningMode({
				recacheRunningMode: RecacheRunningModeModel.INITIALIZATION
			})
		);

		this.store.dispatch(
			recacheAllMachines({
				recacheRunningMode: RecacheRunningModeModel.INITIALIZATION,
				currentUserRoles: userRoles
			})
		);
	}

	private async _dismissLoading() {
		if (this.loadingInPresent) {
			if (this.loading) {
				this.loading.dismiss();
				this.loadingInPresent = false;
				this.loading = undefined;
			} else {
				setTimeout(() => {
					this._dismissLoading();
				}, 500);
			}
		}
	}

	private _presentLoading() {
		this.loadingInPresent = true;
		this.loadingController
			.create({
				message: this.translationService.instant(
					'loading.searchMachine.content'
				)
			})
			.then(loading => {
				this.loading = loading;
				this.loading.present();
			});
	}
}
