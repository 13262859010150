import { BehaviorSubject } from 'rxjs';
import { App } from '@capacitor/app';
import {isPlatform, Platform} from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import { Injectable, NgZone } from '@angular/core';

import { LoggerService } from './logger.service';
import { TranslateService } from './translate.service';
import { OfflineReminder } from '@shared/shared.module';
import { StatusBar, Style } from '@capacitor/status-bar';
import { ConfigurationService } from '@core/configuration.service';
import { AuthService } from '@core/services/auth/auth.service';
const { isProduction } = ConfigurationService.environment.$environment;
const { authorizationParams } = ConfigurationService.environment.mobileAuthConfig;
const iosOrAndroid = isPlatform('ios') || isPlatform('android');
@Injectable({ providedIn: 'root' })
export class ApplicationService {
	public isReady$ = new BehaviorSubject<boolean>(false);

	constructor(
		private readonly ngZone: NgZone,
		private readonly platform: Platform,
		private readonly authService: AuthService,
		private readonly loggerService: LoggerService,
		private readonly offlineReminder: OfflineReminder,
		private readonly translateService: TranslateService
	) {}

	public initilizeApplication(): Promise<void> {
		try {
			if (isProduction) {
				this.loggerService.setProductionMode();
			}
		} catch (error) {
			this.loggerService.info(`Production mode already set. ${error}`);
		}

		return new Promise((resolve, reject) => {
			this.platform
				.ready()
				.then(() => {
					this.handleRedirect();
					this.loadAppDependencies();
					resolve();
				})
				.catch(reject);
		});
	}

	private handleRedirect() {
		App.addListener('appUrlOpen', ({ url }) => {
			this.ngZone.run(() => {
				if (url?.startsWith(authorizationParams.redirect_uri)) {
					if (
						url.includes('state=') &&
						(url.includes('error=') || url.includes('code='))
					) {
						this.authService.handleRedirectCallback(url);
						console.log('browser closed with callback uri: ', authorizationParams.redirect_uri);
					} else {
						Browser.close();
						console.log('browser closed without callback uri');
					}
				}
			});
		});
	}

	private async loadAppDependencies(): Promise<void> {
		const onCapacitor = this.onLoadedEnvironment();
		const translations = this.translateService.initTranslate();
		const offlineReminder = this.initializeOfflineReminderService();

		return Promise.all([onCapacitor, translations, offlineReminder]).then(() =>
			this.isReady$.next(true)
		);
	}

	private onLoadedEnvironment(): Promise<any> {
		return new Promise<any>(resolve => {
			if (iosOrAndroid) {
				StatusBar.setOverlaysWebView({ overlay: false });
				StatusBar.setBackgroundColor({ color:'#ffffff'});
				StatusBar.setStyle({ style: Style.Default });
			}
		});
	}

	private initializeOfflineReminderService(): Promise<any> {
		return new Promise<any>(resolve => {
			this.offlineReminder.init();
			resolve(undefined);
		});
	}
}
