// Basic
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { InjectionToken } from '@angular/core';

// Stores
import { PermissionStore } from '@providers/stores/permission-store.service';

// Services
import { UniqueDeviceIDService } from '@providers/services/unique-device-id.service';
import { LoggerService } from '@providers/services/logger.service';

// Errors
import {
	ApiError,
	ApiErrorBadInput,
	ApiErrorNotFound,
	ApiErrorTimeOut,
	ApiErrorExceededRequests,
	ApiErrorForbidden,
	ApiErrorUnauthorized
} from '@core/error/api-error/api-error.module';

// RXJS
import { throwError } from 'rxjs';
import { TimeoutError } from 'rxjs';
import { timeout, map, catchError } from 'rxjs/operators';

import { ConfigurationService } from './configuration.service';

/**
 *
 * Constant with api key value
 */
const { apiKeyValue, baseEndpoint, apiBaseEndpoint } =
	ConfigurationService.environment.$apiConfig;

const URL_TOKEN = new InjectionToken<string>('_urlToken', {
	providedIn: 'root',
	factory: () => `${baseEndpoint}${apiBaseEndpoint}`
});

const API_KEY = new InjectionToken<string>('_apiKey', {
	providedIn: 'root',
	factory: () => apiKeyValue
});

// Constants
import { API_CONSTANTS } from '../core/constants/api.constants';
import { AppInjector } from './app.injector';

/**
 * export the token to get the reference externally.
 */

/**
 * Generic class for API management
 */

export class ApiGenericProvider {
	/**
	 * headers as an object
	 */
	private headers: any;
	protected url: string;
	protected apiKey: string;
	protected http: HttpClient;
	protected uniqueDeviceIDService: UniqueDeviceIDService;
	protected loggerService: LoggerService;
	protected permissionStore: PermissionStore;

	/**
	 * ApiGenericProvider constructor set the APIKey to the object
	 * @param url URL to do the call to.
	 * @param http HTTP service to do the calls.
	 * @param geolocationService Geolocation service to get the current location.
	 * @param uniqueDeviceIDService Service to get the devices' unique ID.
	 * @param loggerService Service to log.
	 * @param permissionStore permissions store
	 */
	constructor(protected uri: string) {
		this.headers = {};
		this.url = AppInjector.injector.get(URL_TOKEN);
		this.apiKey = AppInjector.injector.get(API_KEY);
		this.http = AppInjector.injector.get(HttpClient);
		this.uniqueDeviceIDService = AppInjector.injector.get(
			UniqueDeviceIDService
		);
		this.loggerService = AppInjector.injector.get(LoggerService);
		this.permissionStore = AppInjector.injector.get(PermissionStore);
	}

	/**
	 * Performs a GET call appending uri after url in the form: {url}/{uri} and headers
	 * @param uri Composition of endpoints and/or ids for the requested object.
	 * @returns Promise that if resolved, will return call response.
	 */
	protected get(uri: string) {
		return this.getCompleteUrl(`${this.url}${this.uri}${uri}`, this.apiKey);
	}
	/**
	 * Performs a GET call appending uri after url in the form: {url}/{uri} and headers
	 * @param uri Composition of endpoints and/or ids for the requested object.
	 * @returns Promise that if resolved, will return call response.
	 */
	protected async getCompleteUrl(url: string, apiKey: string) {
		const headers: HttpHeaders = new HttpHeaders({
			...this.headers,
			[API_CONSTANTS.API_KEY]: apiKey
		});
		return this.http
			.get(url, { headers })
			.pipe(
				timeout(30000), // ?
				map(response => response),
				catchError(this.handleError)
			)
			.toPromise();
	}

	/**
	 * Performs a POST call to {url} in order to create an object. Body call specifies object structure.
	 * @param resource Structure specifying new object creation.
	 * @returns Promise that if resolved, will return call response.
	 */
	protected create(resource: any) {
		return this.http
			.post(this.url, JSON.stringify(resource))
			.pipe(
				timeout(30000), // ?
				map(response => response),
				catchError(this.handleError)
			)
			.toPromise();
	}

	/**
	 * Performs a PATCH call to {url}/{id} in order to update an object. Body call specifies object structure. Body is a constant { isRead: true }
	 * @param resource Object that contains object id to be updated
	 * @returns Promise that if resolved, will return call response.
	 */
	protected update(resource: any) {
		return this.http
			.patch(
				`${this.url}${encodeURIComponent(resource.id)}`,
				JSON.stringify({ isRead: true })
			)
			.pipe(
				timeout(30000), // ?
				map(response => response),
				catchError(this.handleError)
			)
			.toPromise();
	}

	/**
	 * Performs a DELETE call to {url}/{id} in order to remove an object.
	 * @param resource Object that contains object id to be updated
	 * @returns Promise that if resolved, will return call response.
	 */
	protected delete(resource: any) {
		return this.http
			.delete(`${this.url}${encodeURIComponent(resource.id)}`)
			.pipe(
				timeout(30000), // ?
				map(response => response),
				catchError(this.handleError)
			)
			.toPromise();
	}

	/**
	 * Function that handles several different errors.
	 * @param error error from response.
	 */
	private handleError(error: HttpErrorResponse) {
		if (error instanceof TimeoutError) {
			return throwError(new ApiErrorTimeOut());
		} else if (error.status === 400) {
			return throwError(new ApiErrorBadInput(error));
		} else if (error.status === 401) {
			return throwError(new ApiErrorUnauthorized());
		} else if (error.status === 403) {
			return throwError(new ApiErrorForbidden());
		} else if (error.status === 404) {
			return throwError(new ApiErrorNotFound());
		} else if (error.status === 429) {
			return throwError(new ApiErrorExceededRequests(error.error));
		} else if (error.status === 0) {
			return throwError(new ApiErrorForbidden());
		} else {
			return throwError(new ApiError(error));
		}
	}

	/**
	 * Method to retrieve GUID and Location information and add them as headers of each request,
	 * only if it comes from Barcode scan.
	 * @param isBarcode Requested from barcode?
	 */
	protected async updateUserInfoValues(isBarcode: boolean) {
		// Ensure the headers are deleted before checking
		delete this.headers['X-Guid'];
		if (isBarcode) {
			const headersObject: any = {};
			try {
				if (this.permissionStore.getGeolocAndGuidSettings()) {
					const guidHeaderValue =
						this.uniqueDeviceIDService.getUniqueDeviceId();
					if (guidHeaderValue) {
						headersObject['X-Guid'] = guidHeaderValue;
					}
				}
			} catch (error) {
				this.loggerService.log(
					this,
					'Plugins not available for including it in the header'
				);
			} finally {
				Object.assign(this.headers, headersObject);
			}
		}
	}
}
