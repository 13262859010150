


let forceTrace = false;
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
  

import jQuery from 'jquery';
import { chalk as chalkMock } from './node-chalk-mock';
import { path as pathMock } from './node-path-mock';

import * as _ from 'lodash';
import * as q from 'q';
import * as moment from 'moment';
import * as dateformat from 'dateformat';
import { Chalk } from 'chalk';
import * as json5 from 'json5';
import type jQueryType from 'jquery';
import type chalkBaseType from 'chalk';
import type * as pathBaseType from 'path';

import { Helpers } from './index';




let $: jQueryType;

$ = jQuery;

/* */
/* */
  




let path = void 0 as typeof pathBaseType;
/* */
/* */
  


// @ts-ignore
path = pathMock;





let chalk: Chalk = void 0 as typeof chalkBaseType;
/* */
/* */
  


// @ts-ignore
chalk = chalkMock;






/* */
/* */
/* */
/* */
  



/**
 * transform unix path to win32 path
 */
const win32Path = (p: string): string => {
/* */
/* */
/* */
/* */
  
  if (/^\/[a-z]\//.test(p)) {
    p = p.replace(/^\/[a-z]\//, `${p.charAt(1).toUpperCase()}:/`);
  }
  return path.win32.normalize(p);
};



/**
 * This funciton will replace // to /
 */
const crossPlatformPath = (
  pathStringOrPathParts: string | string[],
): string => {
  if (Array.isArray(pathStringOrPathParts)) {
    pathStringOrPathParts = pathStringOrPathParts.join('/');
  }

  if (typeof pathStringOrPathParts !== 'string') {
    return pathStringOrPathParts;
  }



  if (
    typeof pathStringOrPathParts === 'string' &&
    /^[A-Z]\:/.test(pathStringOrPathParts)
  ) {
    pathStringOrPathParts = _.lowerFirst(pathStringOrPathParts);
  }

  const isExtendedLengthPath = /^\\\\\?\\/.test(pathStringOrPathParts);
  const hasNonAscii = /[^\u0000-\u0080]+/.test(pathStringOrPathParts); // eslint-disable-line no-control-regex
  if (isExtendedLengthPath) {
    console.warn(`[taon-core][crossPlatformPath]: Path starts with \\\\,
    this is not supported in crossPlatformPath`);
    if (forceTrace) {
      console.trace(`path: "${pathStringOrPathParts}"`);
    }
  }

  if (hasNonAscii) {
    const allNonAscii = pathStringOrPathParts.match(/[^\u0000-\u0080]+/g) || '';
    Helpers.logWarn(
      `[taon-core][crossPlatformPath]: Path contains non-ascii characters: ${allNonAscii}`,
    );
    Helpers.logWarn(pathStringOrPathParts);
  }

  pathStringOrPathParts = (pathStringOrPathParts || '')
    .replace(/\\/g, '/')
    .replace(/\/\//g, '/')
    .replace(/\/\//g, '/'); // TODO probably not needed

  let isWindows = false;
/* */
/* */
/* */
/* */
  

  const regexWinPath1 = /^(\/)[a-zA-Z]\:/;


  if (isWindows && regexWinPath1.test(pathStringOrPathParts)) {
    pathStringOrPathParts = pathStringOrPathParts.slice(1);
  }











  return pathStringOrPathParts;
};



export {   _,   q,   moment,   dateformat,   crossPlatformPath,   win32Path,   path,   chalk,   json5,   $, };/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */


/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
/* */
  


 ;({}); // @--end-of-file-for-module=tnp-core lib/core-imports.ts