import { NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import * as favoritesReducer from './state/favorites.reducer';
import { StoreModule } from '@ngrx/store';
import { FavoritesEffects } from './state/favorites.effects';
import { EffectsModule } from '@ngrx/effects';
import { ConfigurationService } from '@core/configuration.service';

@NgModule({ imports: [CommonModule,
        StoreModule.forFeature(favoritesReducer.favoritesFeatureKey, favoritesReducer.reducer),
        EffectsModule.forFeature([FavoritesEffects])], providers: [FavoritesEffects, ConfigurationService, provideHttpClient(withInterceptorsFromDi())] })
export class FavoritesModule {}
