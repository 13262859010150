const androidAppId = 'com.siemens.simotics.digitaldata'
const iOSAppId = 'com.siemens.simoticsdigitaldata';

//const isIOS = true;

const appId = process.env.PLATFORM === 'android'
	? androidAppId
	: iOSAppId;
const domain = 'siemens-qa-00103.eu.auth0.com';
const baseEndpoint = 'https://service.sidriveiqtwin-mc-q.siemens.cloud/';

export const environment = {
	$environment: {
		name: 'Test',
		isProduction: false,
		business: 'Motion Control Applications',
		businessId: 'MC'
	},
	appInfo: {
		appId,
		name: 'SIMOTICS Digital Data',
		androidPackageName: 'com.siemens.simotics.digitaldata',
		domain
	},
	html5BaseHref: '/',
	$apiConfig: {
		baseEndpoint,
		securityEndpoint: '',
		moreInformationURLEndpoint: 'https://www.sow.siemens.com/',
		industryMallEndpoint: 'https://mall.industry.siemens.com/mall/Catalog/Product/',
		manageAccountEndpoint: 'https://uss.login-qa.siemens.com/',
		apiBaseEndpoint: 'api/',
		apiVersion: '',
		apiKeyValue: 'com-siemens-mc-digitaldata-frontend=6fa741de1bdd1d91830ba'
	},
	mobileAuthConfig: {
		domain: domain,
		clientId: 'mHnR7CfiYK37I95Fi6mJsIXi7qdXxFpA',
		authorizationParams: {
			redirect_uri: `${appId}://${domain}/capacitor/${appId}/callback`,
			audience: 'https://custom_api_auth0/v1',
			prompt: 'login',
			screen_hint: "login",
			connection: 'main-tenant-oidc',
			scope: 'openid profile email offline_access'
		}
	},
	webAuthConfig: {
		domain: domain,
		clientId: '9XcAyNE3NyJJ9z3bltvBn2cAEy1RdhFj',
		httpInterceptor: {
			allowedList: [`${baseEndpoint}/*`]
		},
		authorizationParams: {
			redirect_uri: 'https://sidriveiqtwin-mc-q.siemens.cloud/',
			audience: 'https://custom_api_auth0/v1',
			screen_hint: "login",
			connection: 'main-tenant-oidc',
			scope: 'openid profile email offline_access'
		}
	}
};
