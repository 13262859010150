import { NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import * as machineReducer from './state/machine.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ApiService } from '@core/services/api/api.service';
import { ConfigurationService } from '@core/configuration.service';
import { MachineEffects } from './state/effects/machine.effects';
import { CacheMachineEffects } from './state/effects/cache.effects';
import { OfflineMachineEffects } from './state/effects/offline.effects';
import { VersionHistoryEffects } from './state/effects/version-history-effects';

@NgModule({ imports: [CommonModule,
        StoreModule.forFeature(machineReducer.machineFeatureKey, machineReducer.reducer),
        EffectsModule.forFeature([
            MachineEffects,
            VersionHistoryEffects,
            CacheMachineEffects,
            OfflineMachineEffects
        ])], providers: [
        ApiService,
        MachineEffects,
        VersionHistoryEffects,
        CacheMachineEffects,
        OfflineMachineEffects,
        ConfigurationService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class MachineModule {}
