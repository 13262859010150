import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import {
	AuthModule,
	AuthConfig,
	AuthHttpInterceptor
} from '@auth0/auth0-angular';
import { isPlatform } from '@ionic/angular';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from '@core/core.module';
import { ConfigurationService } from '@core/configuration.service';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { IonicStorageModule } from '@ionic/storage-angular';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { Globalization } from '@awesome-cordova-plugins/globalization/ngx';
import { ImagePicker } from '@awesome-cordova-plugins/image-picker/ngx';
//import { Base64 } from '@ionic-native/base64/ngx';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';

import { ionicConfig } from '@config/ionic.config';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { RootStoreModule } from 'app/state';
import { LoaderModule } from '@app/components/loader/loader.module';
import { HttpInterceptorService } from '@providers/services/log-all-methods';


const { mobileAuthConfig, webAuthConfig } = ConfigurationService.environment;
const iosOrAndroid = isPlatform('ios') || isPlatform('android');
const authConfig: AuthConfig = iosOrAndroid
    ? mobileAuthConfig as AuthConfig
    : { ...webAuthConfig as AuthConfig };

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/locale-', '.json');
}

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], 
    imports: [
        CoreModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AuthModule.forRoot(authConfig),
        IonicStorageModule.forRoot(),
        BrowserModule,
        IonicModule.forRoot(ionicConfig),
        AppRoutingModule,
        BrowserAnimationsModule,
        RootStoreModule,
        LoaderModule
    ], 
    providers: [
            Diagnostic,
            Globalization,
            ImagePicker,
            BarcodeScanner,
            InAppBrowser,
            {
                provide: HTTP_INTERCEPTORS,
                useClass: HttpInterceptorService,
                multi: true
            },
            {
                provide: HTTP_INTERCEPTORS,
                useClass: AuthHttpInterceptor,
                multi: true
            },
            {
                provide: RouteReuseStrategy,
                useClass: IonicRouteStrategy
            },
            {
                provide: Window,
                useValue: window
            },
            provideHttpClient(withInterceptorsFromDi())
        ] 
    })
export class AppModule {}
