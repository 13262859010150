import { NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import * as appReducer from './state/app.reducer';
import { StoreModule } from '@ngrx/store';

@NgModule({ imports: [CommonModule,
        IonicModule,
        StoreModule.forFeature(appReducer.appFeatureKey, appReducer.reducer)], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class AppStatusModule {}
